<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name:
            this.backLinkName.indexOf('r_edit') !== -1
              ? 'r_resources-help-online-edit-type'
              : 'r_resources-help-online-add-type'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName("resources", "resource-types", "resource-types")
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('resources', 'resource-types', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      class="clebex-item-section"
      v-if="listOfResourceTypes && listOfResourceTypes.length"
    >
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  id="timeZoneRadioNotDefined"
                  type="checkbox"
                  v-model="notDefined"
                />
                <label for="timeZoneRadioNotDefined"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{
                displayLabelName("resources", "resource-types", "not-defined")
              }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <ul
      class="clebex-item-section pill"
      v-if="listOfResourceTypes && listOfResourceTypes.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="resourceType in listOfResourceTypes"
        :key="resourceType.id"
      >
        <div class="clebex-item-content-wrapper">
          <div class="checkbox tiny block alt">
            <input
              type="radio"
              :id="`res-level${resourceType.id}`"
              name="res-level"
              :value="resourceType.id"
              :checked="
                selectedResourceType &&
                  selectedResourceType.id === resourceType.id
              "
              @change="selectResource(resourceType)"
            />
            <label :for="`res-level${resourceType.id}`">
              <icon icon="#cx-app1-checkmark" />
              <dl class="clebex-item-dl no-left-padding">
                <dd class="clebex-item-dd">
                  <div class="link">
                    {{ resourceType.name }}
                  </div>
                </dd>
              </dl>
            </label>
          </div>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "SelectResourceType",
  mixins: [helpOnlineMixin],
  data() {
    return {
      notDefined: false,
      searchQuery: "",
      helpSlug: "resources-resource-types"
    };
  },
  computed: {
    ...mapState("resource", [
      "resource",
      "resourceTypes",
      "selectedResourceType"
    ]),
    listOfResourceTypes() {
      const query = this.searchQuery;
      if (this.resourceTypes && this.resourceTypes.length) {
        if (query && query.length > 1) {
          return this.resourceTypes.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.resourceTypes;
        }
      }
      return null;
    }
  },
  created() {
    if (this.backLinkName.indexOf("r_edit") !== -1) {
      const { resource } = this;

      if (
        resource &&
        resource.data &&
        this.canItem(this.resource.data, "update")
      ) {
        this.getResourceTypes();
      } else {
        this.$router.push({ name: "r_resources" });
      }
    } else {
      this.getResourceTypes();
    }
  },
  watch: {
    notDefined() {
      if (this.notDefined) {
        this.setSelectedResourceType(null);
      }
    }
  },
  methods: {
    ...mapActions("resource", ["getResourceTypes", "setSelectedResourceType"]),
    selectResource(resource) {
      this.setSelectedResourceType(resource);
      this.$router.push({ name: this.backLinkName });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
